(function() {
	'use strict';

	if(window.history.length>1) {

		$('.js-backlink').toggle();

		$('.js-backlink').on('click', function(event) {
			history.go(-1);
			event.preventDefault();
		});
	}


})();

